
import React, { useState, useEffect, useRef, useContext } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {APIKit} from '../../assets/serverURL/serverConnection'
import {AuthenticationContext} from "../../App"
import { Link, useHistory} from 'react-router-dom';
import {Routes} from "../../routes"

interface Column {
  id: 'name' | 'description' | 'Status' | 'Block' | 'fixByWeek';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'name', label: 'Nombre de actividad', minWidth: 170 },
  { id: 'description', label: 'Descripción', minWidth: 100 },
  {
    id: 'active',
    label: 'Estado',
    minWidth: 170,
    align: 'right',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'block',
    label: 'Bloqueado',
    minWidth: 170,
    align: 'right',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'fixByWeek',
    label: 'Fijo por semana',
    minWidth: 170,
    align: 'right',
    format: (value: number) => value.toFixed(2),
  },
];

export default function ColumnGroupingTable() {
  const navigate = useHistory();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [rows, setRows] = useState([])
  const {token, setToken} = useContext(AuthenticationContext)
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    if(!localStorage.getItem("token")){
      return navigate.push(Routes.Signin.path);
    }
    getActivities();
  }, []);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getActivities = async () => {
    await APIKit.get('activity/list', {header:{'Autorization': token}}).then((response) => {
      const item = response.data.response
      setRows(item)
        }).catch (async error => {
      alert(error)
    });
  }

  return (
    <Paper sx={{ width: '100%' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={5}>
                Actividades
              </TableCell>
            </TableRow>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ top: 57, minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                    {columns.map((column, index) => {
                      let value
                      switch(index) {
                        case 0:
                          value = row.name
                          break;
                        case 1:
                          value = row.description
                          break;
                        case 2:
                          value = row.active ? 'Activo': 'Inactivo'
                          break;
                        case 3:
                          value = row.block ? 'Sí': 'No'
                          break;
                        case 4:
                          value = row.fixByWeek ? 'Sí' : 'No'
                          break;
                        default:
                          value = 'N/A'
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="dic"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
