import React, { useState, useEffect, useRef, useContext } from 'react'
import ReactDOM from 'react-dom';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import {APIKit, DownloadAPIKit} from "../assets/serverURL/serverConnection";
import { Col, Row, Card, Form, InputGroup, FormCheck } from '@themesberg/react-bootstrap';
import { default as ReactSelect } from "react-select";
import moment from "moment-timezone";
import { Next } from '@themesberg/react-bootstrap/lib/esm/PageItem';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Datetime from "react-datetime";
import {TailSpin} from 'react-loader-spinner'
import { Alert } from "@mui/material";
import fileDownload from 'js-file-download';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCalendar, faEdit} from "@fortawesome/free-solid-svg-icons";
import { AuthenticationContext } from '../App';
import { PermissionsContext } from '../App';
import { Link, useHistory} from 'react-router-dom';
import {Routes} from "../routes"

export default () => {
    let calendarRef = React.useRef();
    const navigate = useHistory();
    const [title, setTitle] = useState('');
    const [subTitle, setSubtitle] = useState('')
    const EventDetail = ({ event, el }) => {
      const content = 
      <div style={{marginTop:20}}>
          <div style={{fontSize: 11 , color: "#464EB8", fontWeight: 'bold' 
          ,fontFamily:'revert', marginLeft: 10, marginTop: 5}}>{event.title}</div>
          <div style={{color: "#505AC9", marginLeft: 10, marginTop: 20}}>{event.extendedProps.description}</div>
          {permissions.includes("edit_schedule") && (
            <div style={{opacity: 0.5}}>
              <FontAwesomeIcon icon={faEdit} style={{position: "absolute", bottom: 5, alignSelf: "flex-end", right: 5}} onClick={()=> handleEdit(event.title, event.extendedProps.scheduleId, event)}/>
            </div>
          )}
      </div>;
      ReactDOM.render(content, el)
      return el;}
    const {token, setToken} = useContext(AuthenticationContext);
    const {permissions, setPermissions} = useContext(PermissionsContext);
    const initialReference = new Date().getDate() - new Date().getDay();
    const [schedule, setSchedule] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [userIds, setUserIds] = useState([]);
    const [scheduleId, setscheduleId] = useState(0);
    const [referenceDay, setReferenceDay] = useState(new Date(new Date().setDate(initialReference)))
    const [id, setId] = useState('')
    const [name, setName] =useState('Select option')
    const [callbackController, setCallbackController] = useState(true)
    const condition = [{block: true}]
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [activityStartAt, setActivityStartAt] = useState('');
    const [activityEndAt, setActivityEndAt] = useState('')
    const [loading, setLoading] = useState(false)
    const [modalLoading, setModalLoading] = useState(false)
    const [showError, setShowError] = useState(false)
    const [buttons, setButtons] = useState('')
    const [professionalList, setProfessionsList] = useState([])
    const [idEdit, setIdEdit] = useState('')
    const [nameEdit, setNameEdit] =useState('Select option')
    const [error, setError] = useState();
    const [numberCanEmpty, setNumberCanEmpty] = useState();
    const [numberCannotEmpty, setNumberCannotEmpty] = useState();
    const [modalSummary, setModalSummary] = useState(false);
    let init = 1
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseEdit = () => setShowEdit(false);
    const handleCloseSummary = () => {
      setModalSummary(false);
      window.location.reload(false);
    };
    const handleShowEdit = () => setShowEdit(true);
    const config = {
      headers: {
        'Authorization': token
      }
    }
    
    const editSchedule = async (idEdit) => { 
      setLoading(true)
      const verified = true;
      await APIKit.post('schedule/edit/' + scheduleId, {
        userId: idEdit,
        verified
      }, config).then((response) => {
        setLoading(false)
        setShowEdit(false)
      }).catch (async error => {
        setError(error.response.data.errors.base)
        setLoading(false)
        setShow(true)
      });
    };

    useEffect(() => {
      if(!localStorage.getItem("token")){
        return navigate.push(Routes.Signin.path);
      }
      if(id && !showEdit)
      {
        var curr = new Date(referenceDay.getTime());
        var first = curr.getDate() - curr.getDay();
        var last = first + 6;
        var firstday = moment(new Date(curr.setDate(first))).format("YYYY-MM-DD") 
        var lastday = moment(new Date(curr.setDate(last))).format("YYYY-MM-DD")
        if(callbackController)
        {
          getSchedule(id, firstday, lastday);
        }
        else
        {
          getScheduleActivity(id, firstday, lastday);
        }
      }
    }, [referenceDay, id, showEdit]);

    useEffect(() => {
      let buttonsName = "";
      let concatenated = false;
      const newPermissions = permissions || localStorage.getItem('permissions');
      if (newPermissions.includes("generate_schedule")) {
        buttonsName += "customGenerate";
        concatenated = true;
      }

      if (newPermissions.includes("generate_excel")) {
        if (concatenated) {
          buttonsName += ",";
        }

        buttonsName += "customDownload";
      }

      setButtons(buttonsName);
    }, [])

    useEffect(() => {
      if(callbackController)
      {
        getProfessional();
      }
      else
      {
        getActivities();
      }
      
    }, [callbackController]);

    const handleEdit = (title, id, edit) =>{
      handleShowEdit(true)
      setTitle(title)
      setscheduleId(id)
    }
    const getSchedule = async (userId, startDate, endDate) => {
      setLoading(true)
        await APIKit.post('schedule/show', {
          startDate,
          endDate,
          userId
        }, config).then((response) => {
        const data = response.data.response.map(data => 
          data.color != '#7B83EB'? {...data, color: '#7B83EB2A', editable: false}: data)
        setSchedule(data)
      }).catch (async error => {
        alert(error)
      });
      setLoading(false)
    };

    const getScheduleActivity = async (activityId, startDate, endDate) => { 
      setLoading(true)
      await APIKit.post('schedule/show',{
        startDate,
        endDate,
        activityId
      }, config).then((response) => {
      const data = response.data.response.map(data => 
      data.color != '#7B83EB'? {...data, color: '#7B83EB2A', editable: false}: data)
      setSchedule(data)
    }).catch (async error => {
      alert(error)
    });
    setLoading(false)
  };

    const getProfessional = async () => { 
      setLoading(true)
      await APIKit.get('activity/getParams', config).then((response) => {
        const item = response.data.response.users.map( value => {
          const object = {
            value : value.id,
            label : value.firstName + " " + value.lastName
          }
          return object
          }
        )
        setItemList(item)
        setProfessionsList(item)
        setName(item[0].label)
        setId(item[0].value)
        setNameEdit(item[0].label)
        setIdEdit(item[0].value)
          }).catch (async error => {
        alert(error)
      });
      setLoading(false)
    }

    const getActivities = async () => {
      setLoading(true)
      await APIKit.get('activity/list?currentlyUsed=true', config).then((response) => {
        const filterData = response.data.response.filter(item => !item.block)
        const item = filterData.map( value => {
          const object = {
            value : value.id,
            label : value.name
          }
          return object
          }
        )
        setItemList(item)
        setName(item[0].label)
        setId(item[0].value)
          }).catch (async error => {
        alert(error)
      });
      setLoading(false)
    }

    const handleGenerate = async (star, end) => {
      setShowError(false)
      setModalLoading(true)
      const startDate = moment(star._d).format("YYYY-MM-DD")
      const endDate = moment(end._d).format("YYYY-MM-DD")
      await APIKit.post('schedule/generate', {
        startDate,
        endDate,
      }, config).then((response) => {
        const data = response.data.response;
        setShow(false)
        setModalLoading(false)
        setNumberCanEmpty(data.numberCanEmpty);
        setNumberCannotEmpty(data.numberCannotEmpty);
        setModalSummary(true);
    }).catch (async error => {
      alert(error)
      setShowError(true)
      setModalLoading(false)
    });
      
    }
    const handleDownload = async () => {
      setLoading(true)
      await APIKit.post('schedule/generateExcel', {}, config).then((response) => {
        setShow(false)
        const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.data.blob}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.target = "_blank";
        downloadLink.download = 'Calendario.xlsx'
        downloadLink.click();
    }).catch (async error => {
      console.log(error)
    });
      setLoading(false)
    }

    return (
      <div>
        {loading &&
          <div style={{position: 'absolute', left: '50%', top: '50%',
          transform: 'translate(-50%, -50%)'}}>
            <TailSpin
            height="80"
            width="80"
            radius="9"
            color="green"
            ariaLabel="loading"
            wrapperStyle
            wrapperClass
            />
          </div>
        }
        <div style={{width: 400, marginBottom:20}}>
        <Row>
            <Col md={50} className="mb-3">
              <Form.Check type="checkbox">
                      <FormCheck.Input id="defaultCheck5" className="me-2" checked={callbackController} 
                      onChange={() => {
                        setCallbackController(!callbackController)
                        }}/>
                      <FormCheck.Label htmlFor="defaultCheck5" className="mb-0" >Usuario</FormCheck.Label>
                      <FormCheck.Input id="defaultCheck5" className="me-2" style={{marginLeft: 10}} checked={!callbackController} 
                      onChange={() => {
                        setCallbackController(!callbackController)
                        }}/>
                      <FormCheck.Label htmlFor="defaultCheck5" className="mb-0" >Actividad</FormCheck.Label>
               </Form.Check>
            </Col>
            <Col md={50}>
            <ReactSelect
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              placeholder={name}
              options={itemList}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              allowSelectAll={true}
              value={userIds}
              onChange={e => {
                setName(e.label)
                setId(e.value)}}
              />
            </Col>            
        </Row>
        </div>  
        <FullCalendar
          allDaySlot={false}
          ref={calendarRef}
          plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          eventDidMount= {EventDetail}
          events={schedule}
          locale='es'
          headerToolbar={{
            left: "customPrev,customNext",
            center: "title",
            right: buttons,
          }}
          customButtons={{
            customNext: {
              text: '>',
              click: function(){
                var curr = referenceDay;
                var first = curr.getDate() - curr.getDay(); 
                var newFirst = first + 7;
                setReferenceDay(new Date(curr.setDate(newFirst)))
                let calendarApi = calendarRef.current.getApi()
                calendarApi.next()
              }
            },
            customPrev: {
              text: '<',
              click: function(){
                var curr = referenceDay;
                var first = curr.getDate() - curr.getDay(); 
                var newLast = first - 7;
                setReferenceDay(new Date(curr.setDate(newLast)))
                let calendarApi = calendarRef.current.getApi()
                calendarApi.prev()
              }
            },
            customGenerate: {
              text: 'Generar calendario',
              margin: '20px',
              click: function(){
                handleShow()
              }
            },
            customDownload:{
              text: 'Descargar',
              marginLeft: 5,
              click: function(){
                handleDownload()
              }
            }
          }}
          editable='false'
          timeZone='none'
          selectable={true}
          height="700px"
        />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Generar nuevo calendario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Group id="activityStartAt">
          <Form.Label>Fecha de inicio</Form.Label>
            <Datetime
              timeFormat={false}
              onChange={setActivityStartAt}
              renderInput={(props, openCalendar) => (
                <InputGroup>
                  <Form.Control
                    type="text"
                    value={activityStartAt ? moment(activityStartAt).format("DD/MM/YYYY") : ""}
                    placeholder="dd/mm/yyyy"
                    onFocus={openCalendar}
                    onChange={() => { }}/>
                </InputGroup>
              )} />
          </Form.Group>
          <Form.Group id="activityEndAt">
            <Form.Label>Fecha de fin</Form.Label>
            <Datetime
              timeFormat={false}
              onChange={setActivityEndAt}
              renderInput={(props, openCalendar) => (
                <InputGroup>
                  <Form.Control
                    type="text"
                    value={activityEndAt ? moment(activityEndAt).format("DD/MM/YYYY") : ""}
                    placeholder="dd/mm/yyyy"
                    onFocus={openCalendar}
                    onChange={() => { }} />
                </InputGroup>
              )} />
        {modalLoading &&
        <div style={{position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'}}>
          <TailSpin
              height="50"
              width="50"
              radius="6"
              color="green"
              ariaLabel="loading"
              wrapperStyle
              wrapperClass
          />
        </div>}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={() => handleGenerate(activityStartAt, activityEndAt)}>
            Generar
          </Button>
        </Modal.Footer>
        {showError && 
        <Alert severity="error">Ocurrió un error al generar el horario</Alert>
        }
      </Modal>
      <Modal show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group id="activityEndAt">
            <Form.Label>Profesional</Form.Label>
            <ReactSelect
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              placeholder={nameEdit}
              options={professionalList}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              allowSelectAll={true}
              value={idEdit}
              onChange={e => {
                setNameEdit(e.label)
                setIdEdit(e.value)}}
              />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() =>handleCloseEdit()}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={() =>editSchedule(idEdit)}>
            Editar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={modalSummary} onHide={handleCloseSummary}>
        <Modal.Header closeButton>
          <Modal.Title>Resumen de Actividades</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Se ha generado el horario exitosamente. {numberCanEmpty == 1 ? "Quedo " : "Quedaron "} {<b className="text-success">{numberCanEmpty}</b>} {numberCanEmpty == 1 ? ' actividad sin asignar que podia quedar vacia' : 'actividades sin asignar que podian quedar vacias'} y {<b className="text-danger">{numberCannotEmpty}</b>} {numberCannotEmpty == 1 ? 'actividad sin asignar que no podia quedar vacia.' : 'que no podian quedar vacias.'} <br/> 
          Para un mayor detalle por favor descargar el excel del horario generado.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() =>handleCloseSummary()}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
    )
}




