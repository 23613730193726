
import React, { useState } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Button, InputGroup, FormCheck } from '@themesberg/react-bootstrap';
import { components } from "react-select";
import { default as ReactSelect } from "react-select";
import {APIKit} from "../assets/serverURL/serverConnection";

export default () => {

  const [professionName, setProfessionName] = useState('');
  const [active, setActive] = useState(true);
  
  const handleSubmit = async (name, active) =>{
    await APIKit.post('/profession/create', {
        name,
        active,
      }).then((response) => {
      alert("Profession created")
    }).catch (async error => {
      alert(error)
    });
  }
  return (
    
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Crear Profesión</h5>
        <Form>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Nombre de la profesión</Form.Label>
                <Form.Control required type="text" placeholder="Ingrese nombre de la nueva profesión" value={professionName} onChange={e => setProfessionName(e.target.value)}/>
              </Form.Group>
            </Col>
           </Row>
          <Row>
            <Col md={10} className="mb-3">
              <Form.Check type="checkbox">
                      <FormCheck.Input id="defaultCheck5" className="me-2" checked={active} onChange={() => setActive(!active)}/>
                      <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Activo</FormCheck.Label>
              </Form.Check>
            </Col>
          </Row>
          <div className="mt-3">
            <Button variant="primary" onClick={()=>handleSubmit(professionName, active)}>Crear</Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};
