import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookMedical, faCertificate, faCheck, faCog, faFileMedicalAlt, faHome, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Card, FormCheck } from '@themesberg/react-bootstrap';
import { TransactionsTable } from "../components/Tables";
import { height } from "dom-helpers";
import { ProfileCardWidget } from "../components/Widgets";

export default () => {
  const [visible, setVisible] = useState(true)
  return (
    <>
      <div className="d-block mb-4 mb-md-0">
        <h4>Activity list</h4>
      </div>
      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">Create</Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      <TransactionsTable />
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="btn-toolbar mb-2 mb-md-0">
        {visible ? (
          <ButtonGroup>
            <Button variant="outline-primary" size="sm" onClick={()=>setVisible(!visible)}>Create activity</Button>
          </ButtonGroup>
          ): (
          <Card>
              <Card.Body className="pt-4">
              <Button className="theme-settings-close" variant="close" size="sm" aria-label="Close" onClick={()=>setVisible(!visible)}/>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <p className="m-0 mb-1 me-3 fs-7">Create Activity</p>
              </div>
              <Form>
              <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faFileMedicalAlt} />
                  </InputGroup.Text>
                    <Form.Control autoFocus placeholder="New Activity" />
                    <Form.Control autoFocus placeholder="Description"/>
                </InputGroup>
                <div style={{margin: 20}}/>
                <Form.Check type="checkbox">
                    <FormCheck.Input id="defaultCheck5" className="me-2" />
                    <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Recurrent</FormCheck.Label>
                    <FormCheck.Input id="defaultCheck5" className="me-2" style={{marginLeft: 10}}/>
                    <FormCheck.Label htmlFor="defaultCheck5" className="mb-0" >Fixed by week</FormCheck.Label>
                </Form.Check>
                
              </Form>
              <div style={{margin: 20}}/>
              <Button target="_blank" variant="primary" className="mb-3 w-100"><FontAwesomeIcon icon={faCertificate} className="me-1" /> Create</Button>
              <div className="d-flex justify-content-center">
            </div>
          </Card.Body>
          </Card>
          )}
        </div>
      </div>

    </>
  );
};
