
import React from "react";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {APIKit} from "../../assets/serverURL/serverConnection";
import { Alert } from "@mui/material";
import {TailSpin} from 'react-loader-spinner';

import { Routes } from "../../routes";


export default () => {
  const [confirmPassword, setConfirmPassword] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState();
  const [show, setShow] = useState(false);
  const navigate = useHistory();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");
  const assignPassword = async (password, confirmPassword) => { 
    setLoading(true)
    await APIKit.post('auth/assignPassword', {
      password,
      confirmPassword,
      token
    }).then((response) => {
      setLoading(false)
      navigate.push(Routes.Signin.path);
    }).catch (async error => {
      setError(error.response.data.errors.base)
      setLoading(false)
      setShow(true)
    });
  };
  return (
    <main>
      {show && 
        <Alert severity="error">{error}</Alert>
      }

      {loading &&
        <div style={{position: 'absolute', zIndex: 100,left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'}}>
          <TailSpin
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          wrapperStyle
          wrapperClass
          />
        </div>
      }
      <section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3 className="mb-4">Crear Contraseña</h3>
                <Form>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Contraseña</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control required type="password" placeholder="Contraseña"
                      onChange={e => setPassword(e.target.value)}/>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="confirmPassword" className="mb-4">
                    <Form.Label>Confirmar Contraseña</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control required type="password" placeholder="Confirmar Contraseña"
                      onChange={e => setConfirmPassword(e.target.value)}/>
                    </InputGroup>
                  </Form.Group>
                  <Button variant="primary" className="w-100" onClick={()=>assignPassword(password, confirmPassword)}>
                    Crear contraseña
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};