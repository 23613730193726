
import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Button, InputGroup, FormCheck } from '@themesberg/react-bootstrap';
import { components } from "react-select";
import { default as ReactSelect } from "react-select";
import {APIKit} from "../assets/serverURL/serverConnection";

export default () => {

  const [professionsList, setProfessionsList] = useState([]);
  const [professionStatus, setProfessionStatus] = useState(false)
  const [professionId, setProfessionId] = useState("");
  const [professionName, setProfessionName] = useState("");

  const handleProfessionToEdit = (props) => {
    const status = professionsList.filter(professionsList => professionsList.name == props);
    setProfessionName(props)
    setProfessionId(status[0].id)
    setProfessionStatus(status[0].active)
  }

  const handleEditProfessionClick = async (name, id, active) => {
    await APIKit.post('/profession/update/'+id, {
      name,
      active,
    }).then((response) => {
    alert("Profession updated succesfully")
  }).catch (async error => {
    alert(error)
  });
  }

  useEffect(() => {
    getProfession();
  }, []);

  const getProfession = async () => { 
    await APIKit.get('profession').then((response) => {
      setProfessionsList(response.data.response)
      setProfessionStatus(response.data.response[0].active)
      setProfessionName(response.data.response[0].name)
      setProfessionId(response.data.response[0].id)
  }).catch (async error => {
    alert(error)
  });
};
  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
        <h5 className="mb-4">Edit Profession</h5>
        <Form>
      <Row>
      <Col md={6} className="mb-3">
          <Form.Group id="gender">
            <Form.Label>Profession list</Form.Label>
            <Form.Select onChange={e => handleProfessionToEdit(e.target.value)}>
              {professionsList.map((data, index) => (
                <option value={data.name} key={data.id}>{data.name}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      </Form>
      <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
            <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Profession Name</Form.Label>
                <Form.Control required type="text" placeholder="Enter the name of the proffesion" value={professionName} onChange={e => setProfessionName(e.target.value)}/>
              </Form.Group>
            </Col>
           </Row>
          <Row>
            <Col md={10} className="mb-3">
              <Form.Check type="checkbox">
                      <FormCheck.Input id="defaultCheck5" onChange={e => setProfessionStatus(!professionStatus)} className="me-2" checked={professionStatus}/>
                      <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Active</FormCheck.Label>
              </Form.Check>
            </Col>
          </Row>
          <div className="mt-3">
            <Button variant="primary" onClick={() => handleEditProfessionClick(professionName, professionId, professionStatus)}>Edit Profession</Button>
          </div>
            </Card.Body>
    </Card>
      </Card.Body>
    </Card>
  );
};