
import moment from "moment-timezone";

export default [
    {
        "invoiceNumber": 1140849764,
        "status": "Active",
        "subscription": "William Toro",
        "price": "37",
        "issueDate": "25/02/1992",
        "dueDate": moment().subtract(1, "days").add(1, "month").format("DD MMM YYYY")
    },
    {
        "invoiceNumber": 1140222333,
        "status": "Vacation",
        "subscription": "Daniel Campo",
        "price": "41",
        "issueDate": "30/03/1995",
        "dueDate": moment().subtract(2, "days").add(1, "month").format("DD MMM YYYY")
    },
    {
        "invoiceNumber": 1140888999,
        "status": "Canceled",
        "subscription": "Jesus Pion",
        "price": "0",
        "issueDate": "26/05/1991",
        "dueDate": moment().subtract(2, "days").add(1, "month").format("DD MMM YYYY")
    }   
]