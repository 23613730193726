import React, {useContext, useState, useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faCalendar, faEnvelope, faUnlockAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link, useHistory} from 'react-router-dom';
import {APIKit} from "../../assets/serverURL/serverConnection";
import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import { Alert } from "@mui/material";
import {TailSpin} from 'react-loader-spinner'
import { AuthenticationContext } from "../../App";
import { PermissionsContext } from "../../App";
import axios from "axios";

export default () => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState();
  const [show, setShow] = useState(false);
  const navigate = useHistory();
  const {token, setToken} = useContext(AuthenticationContext);
  const {permissions, setPermissions} = useContext(PermissionsContext);
  const signIn = async (username, password) => { 
      setLoading(true)
      await APIKit.post('auth/login', {
        username,
        password,
      }).then((response) => {
      setLoading(false)
      const data = JSON.stringify(response.data);
      const parseData = JSON.parse(data);
      if (parseData.changePassword) {
        return navigate.push(Routes.AssignPassword.path + '?token=' + parseData.response);
      }

      setToken(response.data.response);
      localStorage.setItem("token", response.data.response);
      setPermissions(response.data.permissions);
      localStorage.setItem("permissions", response.data.permissions);
      navigate.push(Routes.DashboardOverview.path);
    }).catch (async error => {
      setError(error.response.data.errors.base)
      setLoading(false)
      setShow(true)
    });
  };

  return (
    <main>
      {show && 
        <Alert severity="error">{error}</Alert>
      }
      {loading &&
        <div style={{position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'}}>
          <TailSpin
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          wrapperStyle
          wrapperClass
          />
        </div>
      }
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${faCalendar})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Scheduler</h3>
                </div>
                <Form className="mt-4">
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Usuario</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUser} />
                      </InputGroup.Text>
                      <Form.Control autoFocus placeholder="Usuario" value={userName}
                      onChange={e => setUserName(e.target.value)}/>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Contraseña</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control required type="password" placeholder="Contraseña" value={password}
                        onChange={e => setPassword(e.target.value)}/>
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <Card.Link className="small text-end" onClick={() => navigate.push(Routes.ForgotPassword.path)}>¿Olvidaste tu contraseña?</Card.Link>
                    </div>
                  </Form.Group>
                  <Button variant="primary" className="w-100" onClick={()=>signIn(userName, password)}>
                    Iniciar sesión
                  </Button>
                </Form>
                <div className="d-flex justify-content-center align-items-center mt-4">
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
