import React, { useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from "react-router-dom";
import {App} from "./App"
import styled from "@emotion/styled";
// core styles
import "./scss/volt.scss";

// vendor styles
import "react-datetime/css/react-datetime.css";

export const StyleWrapper = styled.div`
  .fc-timegrid-event-harness {
    margin-top: 4px;
    border-left: 3px solid #464EB8;
  }
`

ReactDOM.render(
  <StyleWrapper>
    <App/>
  </StyleWrapper>
  ,
  document.getElementById("root")
);
