import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook, faBoxOpen, faBriefcaseMedical, faCartArrowDown, faChartPie, faChevronDown, faClipboard, faCommentDots, faEdit, faFileAlt, faFileMedicalAlt, faFolderOpen, faFolderPlus, faPlus, faRocket, faStore } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button } from '@themesberg/react-bootstrap';
import { ChoosePhotoWidget, ProfileCardWidget } from "../components/Widgets";
import Dropdown from 'react-bootstrap/Dropdown';
import Profile3 from "../assets/img/team/profile-picture-3.jpg";


export default () => {

  const [selectOption, setSeletOption] = useState(0);
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Row>
          <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            To do
          </Dropdown.Toggle>
          <Dropdown.Menu>

          </Dropdown.Menu>
        </Dropdown>
        </Row>
      </div>
    </>
  );
};
