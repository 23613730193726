import HomePage from "./pages/HomePage";
import ScrollToTop from "./components/ScrollToTop";
import styled from "@emotion/styled";
import React, { useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from "react-router-dom";
import { useEffect } from "react";


export const AuthenticationContext = React.createContext('');
export const PermissionsContext = React.createContext('');

export const App = () => {

  const [token, setToken] = useState('')
  const [permissions, setPermissions] = useState('')
  const value = useMemo
    (
      () => ({ token, setToken }), 
      [token]
    );
  const configPermissions = useMemo(
    () => ({ permissions, setPermissions }), 
    [permissions]
  );

  useEffect(() => {
    const localToken = localStorage.getItem("token");
    const localPermissions = localStorage.getItem("permissions");
    if (localToken) setToken(localToken);
    if (localPermissions) setPermissions(localPermissions);
  }, [])

  return (
    <AuthenticationContext.Provider value={value}>
      <PermissionsContext.Provider value={configPermissions}>
        <HashRouter>
          <ScrollToTop/>
          <HomePage />
        </HashRouter>
      </PermissionsContext.Provider>
    </AuthenticationContext.Provider>
  )
}