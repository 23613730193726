
import React from "react";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import {APIKit} from "../../assets/serverURL/serverConnection";
import { Link, useHistory, useLocation } from 'react-router-dom';
import {TailSpin} from 'react-loader-spinner';
import { Alert } from "@mui/material";

import { Routes } from "../../routes";

export default () => {
  const navigate = useHistory();
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState();
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState();

  const forgotPassword = async (email) => { 
    setLoading(true)
    await APIKit.post('auth/recoverPassword', {
      email
    }).then((response) => {
      setLoading(false)
      setShow(true)
    }).catch (async error => {
      setError(error.response.data.errors.base)
      setLoading(false)
    });
  };

  return (
    <main>
      {show && 
        <Alert severity="success">Se envio el correo para recuperar contraseña.</Alert>
      }
      {loading &&
        <div style={{position: 'absolute', zIndex: 100,left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'}}>
          <TailSpin
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          wrapperStyle
          wrapperClass
          />
        </div>
      }
      <section className="vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link as={Link} to={Routes.Signin.path} className="text-gray-700">
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Volver a inicio de sesión
            </Card.Link>
            </p>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3>¿Olvidó su contraseña?</h3>
                <p className="mb-4">Ingresa tu correo electrónico para resetear tu contraseña</p>
                <Form>
                  <div className="mb-4">
                    <Form.Label htmlFor="email">Your Email</Form.Label>
                    <InputGroup id="email">
                      <Form.Control autoFocus type="email" placeholder="Ingrese su correo electrónico" 
                      onChange={e => setEmail(e.target.value)}/>
                    </InputGroup>
                  </div>
                  <Button variant="primary" className="w-100" onClick={()=>forgotPassword(email)}>
                    Recuperar contraseña
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
