
import React, { useState, useEffect, useContext} from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Button, InputGroup, FormCheck } from '@themesberg/react-bootstrap';
import { components } from "react-select";
import { default as ReactSelect } from "react-select";
import {APIKit} from "../assets/serverURL/serverConnection";
import { Alert } from "@mui/material";
import { faTumblrSquare } from "@fortawesome/free-brands-svg-icons";
import {TailSpin} from 'react-loader-spinner';
import { AuthenticationContext } from "../App";
import { Link, useHistory} from 'react-router-dom';
import {Routes} from "../routes"

export default () => {
  const navigate = useHistory();
  const {token, setToken} = useContext(AuthenticationContext) 
  const [activityName, setActivityName] = useState('');
  const [description, setDescription] = useState('');
  const [recurrent, setRecurrent] = useState(false);
  const [frequency, setFrequency] = useState('');
  const [selectedDays, setSelectedDays] = useState([])
  const [activityStartAt, setActivityStartAt] = useState('');
  const [activityEndAt, setActivityEndAt] = useState('')
  const [journeyId, setJourneyId] = useState(1);
  const [block, setBlock] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [alternated, setAlternated] = useState(false);
  const [fixByWeek, setFixByWeek] = useState(false);
  const [userIds, setUserIds] = useState([])
  const [professional, setProfessional] = useState([])
  const [show, setShow] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [activityList, setActivityList] = useState([]);
  const [activityId, setActivityId] = useState([]);
  const [alternatedUser, setAlternatedUser] = useState([]);

  const days = [
    { value: "MON", label: "Lunes" },
    { value: "TUE", label: "Martes" },
    { value: "WED", label: "Miércoles" },
    { value: "THU", label: "Jueves" },
    { value: "FRI", label: "Viernes" },
    { value: "SAT", label: "Sábado" },
    { value: "SUN", label: "Domingo" }
  ];

  const config = {
    headers: {
      'Authorization': token
    }
  }

  const refresh = () => {
    setActivityName('')
    setDescription('')
    setRecurrent(false)
    setFrequency('')
    setSelectedDays([])
    setActivityStartAt('')
    setActivityEndAt('')
    setJourneyId(1)
    setBlock(false)
    setEmpty(false)
    setAlternated(false)
    setFixByWeek(false)
    setUserIds([]);
    setProfessional([]);
    setActivityList([]);
    setActivityId([]);
  }
  const handleProff = (props) => {
    setUserIds(props)
  };
  const handleSchedule = (props) => {
    setJourneyId(props)
  }

  const handleRelation = (props) => {
    setActivityId(props)
  };

  const handleSubmit = (name, description, recurrent, activityStartAt, activityEndAt, journeyId,
    block, empty, fixByWeek, selectedDays, id, activityid, alternatedUser) =>{
      alternatedUser = alternated && alternatedUser ? alternatedUser.value : null;
      let postDays
      let frequency
      let start
      let end
      let journeyStart
      let journeyEnd
      if(recurrent){
        postDays = selectedDays.map(data => {return data.value})
        frequency = '0 0 * * ' + postDays.join(',')
        start = null
        end = null
      }
      else{
        postDays = null
        frequency = null
        start = moment(activityStartAt._d).format("YYYY-MM-DD")
        end = moment(activityEndAt._d).format("YYYY-MM-DD")
      }
      const postId = id.map(data => {return data.value})

      if(journeyId == 1){
        journeyStart = '2022-01-01 03:00:00'
        journeyEnd = '2022-01-01 07:00:00'
      }
      else{
        journeyStart = '2022-01-01 09:00:00'
        journeyEnd = '2022-01-01 13:00:00'
      }
      createAcivity(name, true, description, recurrent, frequency, 
        start, end, journeyId, block, empty, fixByWeek, journeyStart, 
        journeyEnd, postId, activityid, alternatedUser)
  }

  useEffect(() => {
    if(!localStorage.getItem("token")){
      return navigate.push(Routes.Signin.path);
    }
    getProfessional();
    getActivity();
  }, []);

  const getProfessional = async () => { 
    await APIKit.get('activity/getParams', config).then((response) => {
      const prof = response.data.response.users.map( value => {
      const object = {
        value : value.id,
        label : value.firstName + " " + value.lastName
      }
      return object
      }
      )
      setProfessional(prof)
        }).catch (async error => {
      alert(error)
    });
  }

  const getActivity = async () => { 
    await APIKit.get('activity/list', config).then((response) => {
      const activity = response.data.response.map( value => {
        const object = {
          value : value.id,
          label : value.name
        }
        return object
        }
        )
        setActivityList(activity)
    }).catch (async error => {
      alert(error)
    });
  };

const createAcivity = async (name, active, description, recurrent, frequency,
  activityStartAt, activityEndAt, 
  journeyId, block, empty, fixByWeek, journeyStartAt, journeyEndAt, userIds, relations, alternatedUser) => { 
  let relation
  if(relations.value){
    relation = relations.value
  }
  else{
    relation = null
  }
  setLoading(true)
  await APIKit.post('activity/create', {
    name, 
    active,
    description,
    recurrent,
    frequency,
    activityStartAt,
    activityEndAt,
    journeyId,
    block,
    empty,
    fixByWeek,
    journeyStartAt,
    journeyEndAt,
    userIds,
    relation,
    alternatedUser
  }).then((response) => {
      setError('')
      setShow(true)
      refresh()
  }).catch (async error => {
      setShow(false)
      setError(error.response.data.errors.base)
  });
  setLoading(false)
};

  return (
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      {loading &&
        <div style={{position: 'absolute', zIndex: 100,left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'}}>
          <TailSpin
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          wrapperStyle
          wrapperClass
          />
        </div>
      }
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Crear Actividad</h5>
        <Form>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Nombre de la actividad</Form.Label>
                <Form.Control type="text" placeholder="Ingrese nombre de la actividad" value={activityName} onChange={e => setActivityName(e.target.value)}/>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>Descripción</Form.Label>
                <Form.Control type="text" placeholder="Ingrese una descripción" value={description} onChange={e => setDescription(e.target.value)}/>
              </Form.Group>
            </Col>
          </Row>
          <Row>
          <Col md={6} className="mb-3"> 
              <Form.Check type="checkbox">
                      <FormCheck.Input id="defaultCheck5" className="me-2" 
                      value={recurrent} 
                      onClick={() => {
                        setRecurrent(!recurrent)
                        }} checked={recurrent}/>
                      <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Recurrente</FormCheck.Label>
              </Form.Check>
            </Col>
            {recurrent ? (
              <Row> 
              <Form.Label>Días</Form.Label>
              <ReactSelect
                  options={days}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  allowSelectAll={true}
                  value={selectedDays}
                  onChange={e => setSelectedDays(e)}/>
            </Row>
                  ):(
                  <Row className="align-items-center">
                    <Col md={6} className="mb-3">
                    <Form.Group id="activityStartAt">
                      <Form.Label>Inicio de actividad</Form.Label>
                      <Datetime
                        timeFormat={false}
                        onChange={setActivityStartAt}
                        renderInput={(props, openCalendar) => (
                          <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                            <Form.Control
                              type="text"
                              value={activityStartAt ? moment(activityStartAt).format("DD/MM/YYYY") : ""}
                              placeholder="dd/mm/yyyy"
                              onFocus={openCalendar}
                              onChange={() => { }}/>
                          </InputGroup>
                        )} />
                    </Form.Group>
                  </Col>
                    <Col md={6} className="mb-3">
                    <Form.Group id="activityEndAt">
                      <Form.Label>Fin de actividad</Form.Label>
                      <Datetime
                        timeFormat={false}
                        onChange={setActivityEndAt}
                        renderInput={(props, openCalendar) => (
                          <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                            <Form.Control
                              type="text"
                              value={activityEndAt ? moment(activityEndAt).format("DD/MM/YYYY") : ""}
                              placeholder="dd/mm/yyyy"
                              onFocus={openCalendar}
                              onChange={() => { }} />
                          </InputGroup>
                        )} />
                    </Form.Group>
                    </Col>
                </Row>
                  )} 
                <Row>
                <Col md={6} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Jornada</Form.Label>
                      <Form.Select value={journeyId} onChange={e => handleSchedule(e.target.value)}>
                        <option value={1}>Diurno</option>
                        <option value={2}>Nocturno</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
          </Row>
          <Row>
            <Col md={10} className="mb-3">
              <Form.Check type="checkbox">
                      <FormCheck.Input id="defaultCheck5" className="me-2" value={block} checked={block}
                      onClick={() => {
                        setBlock(!block)
                        }}/>
                      <FormCheck.Label htmlFor="defaultCheck5" className="mb-0" >Bloqueado</FormCheck.Label>
                      <FormCheck.Input id="defaultCheck5" className="me-2" style={{marginLeft: 10}} value={fixByWeek} 
                      onClick={() => {
                        setFixByWeek(!fixByWeek)
                        }} checked={fixByWeek}/>
                      <FormCheck.Label htmlFor="defaultCheck5" className="mb-0" >Fijo por semana</FormCheck.Label>
                      <FormCheck.Input id="defaultCheck5" className="me-2" style={{marginLeft: 10}} value={empty} 
                      onClick={() => {
                        setEmpty(!empty)
                        }} checked={empty}/>
                      <FormCheck.Label htmlFor="defaultCheck5" className="mb-0" >Vacío</FormCheck.Label>
                      <FormCheck.Input id="defaultCheck5" className="me-2" style={{marginLeft: 10}} value={alternated} 
                      onClick={() => {
                        setAlternated(!alternated)
                        }} checked={alternated}/>
                      <FormCheck.Label htmlFor="defaultCheck5" className="mb-0" >Alternado</FormCheck.Label>
              </Form.Check>
            </Col>
          </Row>
          <Row>
          <Row styles={{marginTop: 10, }}> 
              <Form.Label>Profesional</Form.Label>
              <ReactSelect
                  options={professional}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  allowSelectAll={true}
                  value={userIds}
                  onChange={e => handleProff(e)}/>
            </Row>
          </Row>
          <div styles={{marginTop:30}}>
          <Row className="mt-3"> 
              <Form.Label>Actividad relacionada</Form.Label>
              <ReactSelect
                  options={activityList}
                  isMulti={false}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  allowSelectAll={true}
                  value={activityId}
                  onChange={e => handleRelation(e)}/>
          </Row>
          {alternated && (
            <Row className="mt-3"> 
              <Form.Label>Usuario Alternado</Form.Label>
              <ReactSelect
                  options={professional}
                  isMulti={false}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  allowSelectAll={true}
                  value={alternatedUser}
                  onChange={e => setAlternatedUser(e)}/>
            </Row>
          )}
          </div>
          <div className="mt-3">
            <Button variant="primary" onClick={()=>handleSubmit(activityName, 
              description, recurrent, activityStartAt, activityEndAt, 
              journeyId, block, empty, fixByWeek, selectedDays, userIds, activityId, alternatedUser)}>Crear</Button>
          </div>
          <div style={{marginTop: 20}}>
            {show && 
                <Alert severity="success">Actividad creada exitosamente</Alert>
            }
            {error && 
                <Alert severity="error">{error}</Alert>
            }
            </div>
        </Form>
      </Card.Body>
    </Card>
    </div>
  );
};
 