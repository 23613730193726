
import React, { useEffect, useState, useContext } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Button, InputGroup, FormCheck } from '@themesberg/react-bootstrap';
import { components } from "react-select";
import { default as ReactSelect } from "react-select";
import {APIKit} from "../assets/serverURL/serverConnection";
import { Alert } from "@mui/material";
import {TailSpin} from 'react-loader-spinner';
import { AuthenticationContext } from "../App";
import { Link, useHistory} from 'react-router-dom';
import {Routes} from "../routes"
import Modal from 'react-bootstrap/Modal';

export default () => {
  const navigate = useHistory();
  const {token, setToken} = useContext(AuthenticationContext)  
  const [activityList, setActivityList] = useState([]);
  const [activityName, setActivityName] = useState('');
  const [active, setActive] = useState(true);
  const [description, setDescription] = useState('');
  const [recurrent, setRecurrent] = useState(false);
  const [selectedDays, setSelectedDays] = useState([])
  const [activityStartAt, setActivityStartAt] = useState('');
  const [activityEndAt, setActivityEndAt] = useState('')
  const [journeyId, setJourneyId] = useState(1);
  const [block, setBlock] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [fixByWeek, setFixByWeek] = useState(false);
  const [journeyStartAt, setJourneyStartAt] = useState('');
  const [journeyEndAt, setJourneyEndAt] = useState('')
  const [userIds, setUserIds] = useState([]);
  const [activityId, setActivityId] = useState('');
  const [professional, setProfessional] = useState([])
  const [selectedActivity, setSelectedActivity] = useState()
  const [relatedActivity, setRelatedActivity] = useState("")
  const [show, setShow] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [alternated, setAlternated] = useState(false);
  const [alternatedUser, setAlternatedUser] = useState([]);
  const [alternatedUserSelected, setAlternatedUserSelected] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false)
  const [deleteErrorAlert, setDeleteErrorAlert] = useState(false)
  const days = [
    { value: "MON", label: "Lunes" },
    { value: "TUE", label: "Martes" },
    { value: "WED", label: "Miércoles" },
    { value: "THU", label: "Jueves" },
    { value: "FRI", label: "Viernes" },
    { value: "SAT", label: "Sábado" },
    { value: "SUN", label: "Domingo" }
  ];
  const handleDays = (params) => {
    setSelectedDays(params)
  };

  const handleProff = (params) => {
    setUserIds(params)
  };

  const handleSelectedActivity = (params) => {
    setSelectedActivity(params)
  }

  const handleActivityRelated = (params) => {
    setRelatedActivity(params)
  }

  const handleAlternatedUser = (params) => {
    setAlternatedUser(params)
    setAlternatedUserSelected(params)
  }

  const handleSchedule = (params) => {
    setJourneyId(params)
  }

  const handleCloseDelete = () => setShowDelete(false);
  const handleDeleteShow = () => setShowDelete(true);
  
  const config = {
    headers: {
      'Authorization': token
    }
  }

  useEffect(() => {
    if(!localStorage.getItem("token")){
      return navigate.push(Routes.Signin.path);
    }
    getActivity();
    getProfessional();
  }, []);

  useEffect(() => {
    if (selectedActivity) {
      getActivityDetails();
    }
  }, [selectedActivity, professional]);

  const getProfessional = async () => { 
    await APIKit.get('activity/getParams', config).then((response) => {
      const prof = response.data.response.users.map( value => {
        const object = {
          value : value.id,
          label : value.firstName + " " + value.lastName
        }
        return object
      })
      setProfessional(prof)
    }).catch (async error => {
      alert(error)
    });
  }

  const deleteActiity = async (id) =>{
    handleCloseDelete()
    setLoading(true)
    await APIKit.post('activity/delete/' + id, config).then((response) => {
      setDeleteAlert(true)
      setDeleteErrorAlert(false)
    }).catch (async error => {
      setDeleteAlert(false)
      setDeleteErrorAlert(true)
    });
    setLoading(false)
    setTimeout(window.location.reload(false), 2000)
  }

  const editActivity = async (params) => {
    setLoading(true)
    if(params.recurrent){
      const  postDays = params.frequency.map(data => {return data.value})
      params.frequency = '0 0 * * ' + postDays.join(',')
      params.activityStartAt = null
      params.activityEndAt = null
    } else{
      params.frequency = null
      params.activityStartAt = moment(params.activityStartAt._d).format("YYYY-MM-DD")
      params.activityEndAt = moment(params.activityEndAt._d).format("YYYY-MM-DD")
    }

    if(params.journeyId == 1){
      params.journeyStartAt = '2022-01-01 03:00:00'
      params.journeyEndAt = '2022-01-01 07:00:00'
    } else{
      params.journeyStartAt = '2022-01-01 09:00:00'
      params.journeyEndAt = '2022-01-01 13:00:00'
    }
    setDeleteAlert(false)
    setDeleteErrorAlert(false)
    params.userIds = params.userIds.map(u => u.value);
    params.alternatedUser = alternated && alternatedUserSelected ? alternatedUserSelected : null;
    await APIKit.post('activity/update/' + params.activityId, params, config).then((response) => {
      setError('')
      setShow(true)
    }).catch (async error => {
      setError(error.response.data.errors.base)
      setShow(false)
    });
    setLoading(false)
  }

  const cronTranslate = (cron, days) =>{
    let saveDays = cron ? cron.replace('0 0 * *', '').replace(' ', '').split(',') : [];
    const filterData = days.filter(day => saveDays.includes(day.value))
    return filterData
  }

  const getActivity = async () => { 
    setLoading(true)
      await APIKit.get('activity/list', config).then((response) => {
        setActivityList(response.data.response)
        setSelectedActivity(response.data.response[0].id)
    }).catch (async error => {
      alert(error)
    });
    setLoading(false)
  };

  const getActivityDetails = async () => {
    setLoading(true)
    await APIKit.get('activity/show/' + selectedActivity, config).then((response) => {
      const data = response.data.response;
      const selectedUserIds = data.users.map(u => u.id);
      const selectedUsers = professional.filter(p => selectedUserIds.includes(p.value));
      const alternated = data.alternatedUser ? true : false;
      const alternatedUser = data.user ? professional.filter(p => [data.user.id].includes(p.value)) : '';
      const alternatedUserSelected = data.user ? data.user.id : '';
      setActivityName(data.name)
      setDescription(data.description)
      setActive(data.active)
      setBlock(data.block)
      setEmpty(data.empty)
      setFixByWeek(data.fixByWeek)
      setRecurrent(data.recurrent)
      setSelectedDays(cronTranslate(data.frequency, days))
      setJourneyId(data.journeyId)
      setJourneyEndAt(data.journeyEndAt)
      setJourneyStartAt(data.journeyStartAt)
      setActivityEndAt(data.activityEndAt)
      setActivityStartAt(data.activityStartAt)
      setUserIds(selectedUsers)
      setRelatedActivity(data.relation)
      setActivityId(data.id)
      setAlternated(alternated)
      setAlternatedUser(alternatedUser)
      setAlternatedUserSelected(alternatedUserSelected)
  }).catch (async error => {
    alert(error)
  });
  setLoading(false)
};
  return (
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      {loading &&
        <div style={{position: 'absolute', zIndex: 100,left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'}}>
          <TailSpin
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          wrapperStyle
          wrapperClass
          />
        </div>
      }
      <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
          <h5 className="mb-4">Editar Actividad</h5>
          <Form>
        <Row>
          <Col md={10} className="mb-3">
            <Form.Group id="gender">
              <Form.Label>Lista de actividades</Form.Label>
              <Form.Select onChange={e => handleSelectedActivity(e.target.value)}>
                {activityList.map((data, index) => (
                  <option value={data.id} key={data.id}>{data.name}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        </Form>

        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <Form>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="firstName">
                  <Form.Label>Nombre de la actividad</Form.Label>
                  <Form.Control required type="text" placeholder="Ingrese nombre de la actividad" value={activityName} onChange={e => setActivityName(e.target.value)}/>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="lastName">
                  <Form.Label>Descripción</Form.Label>
                  <Form.Control required type="text" placeholder="Ingrese una descripción" value={description} onChange={e => setDescription(e.target.value)}/>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={10} className="mb-3">
                <Form.Check type="checkbox">
                        <FormCheck.Input id="defaultCheck5" className="me-2" 
                        checked={recurrent}
                        style={{marginLeft: 10}}
                        onChange={() => {
                          setRecurrent(!recurrent)
                          }}/>
                        <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Recurrente</FormCheck.Label>
                
                </Form.Check>
              </Col>
              {recurrent ? (
                <Row> 
                <Form.Label>Días</Form.Label>
                <ReactSelect
                    required
                    options={days}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    allowSelectAll={true}
                    value={selectedDays}
                    onChange={e => handleDays(e)}/>
              </Row>
                    ):(
                    <Row className="align-items-center">
                      <Col md={6} className="mb-3">
                      <Form.Group id="activityStartAt">
                        <Form.Label>Inicio de actividad</Form.Label>
                        <Datetime
                          timeFormat={false}
                          onChange={setActivityStartAt}
                          renderInput={(props, openCalendar) => (
                            <InputGroup>
                              <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                              <Form.Control
                                required
                                type="text"
                                value={activityStartAt ? moment(activityStartAt).format("DD/MM/YYYY") : ""}
                                placeholder="dd/mm/yyyy"
                                onFocus={openCalendar}
                                onChange={() => { }} />
                            </InputGroup>
                          )} />
                      </Form.Group>
                    </Col>
                      <Col md={6} className="mb-3">
                      <Form.Group id="activityEndAt">
                        <Form.Label>Finde actividad</Form.Label>
                        <Datetime
                          timeFormat={false}
                          onChange={setActivityEndAt}
                          renderInput={(props, openCalendar) => (
                            <InputGroup>
                              <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                              <Form.Control
                                required
                                type="text"
                                value={activityEndAt ? moment(activityEndAt).format("DD/MM/YYYY") : ""}
                                placeholder="dd/mm/yyyy"
                                onFocus={openCalendar}
                                onChange={() => { }} />
                            </InputGroup>
                          )} />
                      </Form.Group>
                      </Col>
                  </Row>
                    )} 
                  <Row>
                  <Col md={6} className="mb-3">
                      <Form.Group id="gender">
                        <Form.Label>Jornada</Form.Label>
                        <Form.Select value={journeyId} onChange={e => handleSchedule(e.target.value)}>
                          <option value={1}>Diurno</option>
                          <option value={2}>Nocturno</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
            </Row>
            <Row>
              <Col md={10} className="mb-3">
                <Form.Check type="checkbox">
                  <FormCheck.Input id="defaultCheck5" checked={block} className="me-2" onChange={() => {
                    setBlock(!block)
                    }}/>
                  <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Bloqueado</FormCheck.Label>
                  <FormCheck.Input id="defaultCheck5" checked={fixByWeek} className="me-2" style={{marginLeft: 10}} onChange={() => {
                    setFixByWeek(!fixByWeek)
                    }}/>
                  <FormCheck.Label htmlFor="defaultCheck5" className="mb-0" >Fijo por semana</FormCheck.Label>
                  <FormCheck.Input id="defaultCheck5" checked={empty} className="me-2" style={{marginLeft: 10}} onChange={() => {
                    setEmpty(!empty)
                    }}/>
                  <FormCheck.Label htmlFor="defaultCheck5" className="mb-0" >Vacío</FormCheck.Label>
                  <FormCheck.Input id="defaultCheck5" className="me-2" style={{marginLeft: 10}} value={alternated} 
                    onClick={() => {
                      setAlternated(!alternated)
                    }} checked={alternated}/>
                  <FormCheck.Label htmlFor="defaultCheck5" className="mb-0" >Alternado</FormCheck.Label>
                </Form.Check>
              </Col>
            </Row>
            <Row>
              <Row> 
                <Form.Label>Profesional</Form.Label>
                <ReactSelect
                    required
                    options={professional}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    allowSelectAll={true}
                    value={userIds}
                    onChange={e => {
                      handleProff(e)}}/>
              </Row>
            </Row>
            <Form.Group className="mt-3" id="gender">
              <Form.Label>Actividades Relacionadas</Form.Label>
              <Form.Select onChange={e => handleActivityRelated(e.target.value)}>
                <option value="" key="-1"> -- Sin Relación -- </option>
                {activityList.map((data, index) => {
                  return data.id == relatedActivity ? <option selected value={data.id} key={data.id}>{data.name}</option>
                  : <option value={data.id} key={data.id}>{data.name}</option>
                })}
              </Form.Select>
            </Form.Group>
            {alternated && (
              <Form.Group className="mt-3" id="gender">
                <Form.Label>Usuario Alternado</Form.Label>
                <Form.Select onChange={e => handleAlternatedUser(e.target.value)}>
                  <option value="" key="-1"> -- Sin Usuario -- </option>
                  {professional.map((data, index) => {
                    return data.value == alternatedUserSelected ? <option selected value={data.value} key={data.value}>{data.label}</option>
                    : <option value={data.value} key={data.value}>{data.label}</option>
                  })}
                </Form.Select>
              </Form.Group>
            )}
            <div className="mt-3">
              <Button variant="primary" onClick={()=>editActivity({
                activityEndAt, activityStartAt, block, description, empty, fixByWeek, frequency: selectedDays, journeyId, journeyEndAt, journeyStartAt, name: activityName, recurrent, relation: relatedActivity, userIds, activityId, alternatedUser
              })}>Editar</Button>
              <Button style={{backgroundColor: 'red', marginLeft: 10}} onClick={()=> handleDeleteShow()} >Eliminiar</Button>
            </div>
            <div style={{marginTop: 20}}>
              {show && 
                  <Alert severity="success">Actividad editada exitosamente</Alert>
              }
              {error && 
                  <Alert severity="error">{error}</Alert>
              }
              </div>
          </Form>
        </Card.Body>
      </Card>
      </div>
        </Card.Body>
      </Card>
      <Modal show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title>¿Desea eliminar la actividad?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>handleCloseDelete()}>
            No
          </Button>
          <Button variant="primary" onClick={()=>deleteActiity(activityId)}>
            Sí
          </Button>
        </Modal.Footer>
      </Modal>
      {deleteAlert && 
        <Alert severity="success">Actividad eliminada exitosamente</Alert>
      }
      {deleteErrorAlert && 
        <Alert severity="error">Ocurrió un error, intente de nuevo más tarde</Alert>
      }
    </div>
  );
};